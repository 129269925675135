import { QueryName } from 'common/models/graphql';
import React, { ReactNode } from 'react';

type QueryMapping = {
  [queryName in QueryName]: number;
};

export const REFRESH_INTERVALS: QueryMapping = {
  [QueryName.listMonitorVaults]: 300000,
  [QueryName.listDexes]: 300000,
};

export enum SupportedChainId {
  ETHEREUM_MAINNET = 1,
  OPTIMISM_MAINNET = 10,
  FLARE_MAINNET = 14,
  ROOTSTOCK_MAINNET = 30,
  BSC_MAINNET = 56,
  POLYGON_MAINNET = 137,
  FUSE_MAINNET = 122,
  X1_TESTNET = 195,
  OPBNB_MAINNET = 204,
  FANTOM_MAINNET = 250,
  FANTOM_TESTNET = 4002,
  FANTOM_SONIC = 64165,
  ZKSYNC_TESTNET = 300,
  ZKSYNC_MAINNET = 324,
  HEDERA_MAINNET = 295,
  HEDERA_TESTNET = 296,
  POLYGON_ZKEVM = 1101,
  GANACHE = 1337,
  KAVA_MAINNET = 2222,
  MANTA_PACIFIC = 169,
  MANTLE_MAINNET = 5000,
  SONIC_MAINNET = 146,
  HORIZEN_MAINNET = 7332,
  HAVEN1_DEVNET = 8110,
  BASE_MAINNET = 8453,
  EVMOS_MAINNET = 9001,
  ARTHERA_MAINNET = 10242,
  ARTHERA_TESTNET = 10243,
  UNREAL = 18233, // testnet
  REAL = 111188, // mainnet
  HARDHAT = 31337,
  MODE_MAINNET = 34443,
  ARBITRUM_MAINNET = 42161,
  CELO_MAINNET = 42220,
  AVALANCHE_MAINNET = 43114,
  ZIRCUIT_TESTNET = 48899,
  ZIRCUIT_MAINNET = 48900,
  LINEA_MAINNET = 59144,
  POLYGON_MUMBAI = 80001,
  BERACHAIN_BARTIO = 80_084,
  BLAST_MAINNET = 81457,
  TAIKO_MAINNET = 167000,
  TAIKO_HEKLA = 167009,
  SCROLL_MAINNET = 534352,
  SEPOLIA = 11155111,
  BLAST_SEPOLIA = 168587773,
  SKALE_EUROPA_TESTNET = 1444673419,
  SKALE_EUROPA = 2046399126,
};

export const chainNames: Record<SupportedChainId, string> = {
  [SupportedChainId.ETHEREUM_MAINNET]: "Ethereum",
  [SupportedChainId.OPTIMISM_MAINNET]: "optimism",
  [SupportedChainId.FLARE_MAINNET]: "Flare",
  [SupportedChainId.ROOTSTOCK_MAINNET]: "Rootstock",
  [SupportedChainId.BSC_MAINNET]: "BSC",
  [SupportedChainId.POLYGON_MAINNET]: "Polygon",
  [SupportedChainId.FUSE_MAINNET]: "Fuse",
  [SupportedChainId.OPBNB_MAINNET]: "opBNB",
  [SupportedChainId.FANTOM_MAINNET]: "Fantom",
  [SupportedChainId.FANTOM_TESTNET]: "fantom-testnet",
  [SupportedChainId.FANTOM_SONIC]: "fantom-sonic",
  [SupportedChainId.HEDERA_MAINNET]: "Hedera",
  [SupportedChainId.HEDERA_TESTNET]: "hedera-testnet",
  [SupportedChainId.POLYGON_ZKEVM]: "Polygon zkEvm",
  [SupportedChainId.GANACHE]: "ganache",
  [SupportedChainId.MANTA_PACIFIC]: "Manta Pacific",
  [SupportedChainId.MANTLE_MAINNET]: "Mantle",
  [SupportedChainId.SONIC_MAINNET]: "Sonic",
  [SupportedChainId.EVMOS_MAINNET]: "Evmos",
  [SupportedChainId.ARTHERA_MAINNET]: "Arthera",
  [SupportedChainId.ARTHERA_TESTNET]: "arthera-testnet",
  [SupportedChainId.UNREAL]: "unreal",
  [SupportedChainId.REAL]: "Real",
  [SupportedChainId.HARDHAT]: "hardhat",
  [SupportedChainId.AVALANCHE_MAINNET]: "avalanche",
  [SupportedChainId.ZIRCUIT_TESTNET]: "zircuit-testnet",
  [SupportedChainId.ZIRCUIT_MAINNET]: "Zircuit",
  [SupportedChainId.SEPOLIA]: "sepolia",
  [SupportedChainId.ARBITRUM_MAINNET]: "Arbitrum",
  [SupportedChainId.MODE_MAINNET]: "Mode",
  [SupportedChainId.POLYGON_MUMBAI]: "polygon-mumbai",
  [SupportedChainId.BERACHAIN_BARTIO]: "bartio",
  [SupportedChainId.BLAST_MAINNET]: "Blast",
  [SupportedChainId.TAIKO_MAINNET]: "Taiko",
  [SupportedChainId.TAIKO_HEKLA]: "taiko-hekla",
  [SupportedChainId.SCROLL_MAINNET]: "Scroll",
  [SupportedChainId.LINEA_MAINNET]: "Linea",
  [SupportedChainId.HORIZEN_MAINNET]: "Horizen",
  [SupportedChainId.HAVEN1_DEVNET]: "haven1-devnet",
  [SupportedChainId.BASE_MAINNET]: "Base",
  [SupportedChainId.ZKSYNC_TESTNET]: "zksync-testnet",
  [SupportedChainId.ZKSYNC_MAINNET]: "zkSync",
  [SupportedChainId.BLAST_SEPOLIA]: "blast-sepolia",
  [SupportedChainId.X1_TESTNET]: "x1-testnet",
  [SupportedChainId.CELO_MAINNET]: "Celo",
  [SupportedChainId.KAVA_MAINNET]: "Kava",
  [SupportedChainId.SKALE_EUROPA_TESTNET]: "skale-europa-testnet",
  [SupportedChainId.SKALE_EUROPA]: "Skale Europa",
};

export const badgeSrc = 'https://ichi-images.s3.amazonaws.com/misc/';

type Partner = {
  name: string;
  logo: string;
  description?: string;
  url?: string;
}

export const partners: Partner[] = [
  {
    name: 'Quantstamp',
    logo: 'quantstamp',
    description: 'Audited oneToken Factory'
  },
  {
    name: 'Solidified',
    logo: 'solidified',
    description: 'Audited oneToken Factory'
  },
  {
    name: 'Certik',
    logo: 'certik',
    description: 'Audited Farming Contract'
  },
  {
    name: 'Pashov Audit Group',
    logo: 'pashov',
    description: ''
  },
  {
    name: 'Bail Security',
    logo: 'bail-security',
    description: '',
  },
  {
    name: 'FYEO',
    logo: 'fyeo',
    description: '',
  },
]

export const communityIcons = [
  {
    name: 'Discord',
    logo: 'discord.svg',
    url: 'https://discord.gg/cxPGjGTy8V'
  },
  {
    name: 'Telegram',
    logo: 'telegram.svg',
    url: 'https://t.me/ichifarm'
  },
  {
    name: 'Medium',
    logo: 'medium.svg',
    url: 'https://medium.com/ichifarm'
  },
  {
    name: 'X',
    logo: 'x.svg',
    url: 'https://x.com/ichifoundation'
  },
]

export const media = [
  {
    name: 'Cointelegraph',
    logo: 'cointelegraph.png'
  },
  {
    name: 'The DeFiant',
    logo: 'The DeFiant.svg'
  },
  {
    name: 'Defiyield',
    logo: 'Defiyield.svg'
  },
  {
    name: 'Decrypt',
    logo: 'decrypt_logo.png'
  },
  {
    name: 'Cointribune',
    logo: 'Contribune.svg'
  },
  {
    name: 'CryptoQuestion',
    logo: 'CryptoQuestion.svg'
  },
]

export const navMenu = [
  {
    title: 'Docs',
    link: 'https://docs.ichi.org/',
    target: 'blank',
  },
]

export const advantagePoints = [
  {
    title: 'Liquidity rebalancing',
    accentedText: 'Automated Market Makers',
    content: 'ICHI helps AMMs cut operational costs and improve efficiency with automated on-chain liquidity rebalancing, reducing the need for manual intervention.',
  },
  {
    title: 'Enhanced scalability',
    accentedText: 'Layer 1 & 2 networks',
    content: 'Long-term network success driven by tying financial incentives to protocol performance and contractually locked TVL.',
  },
  {
    title: 'Increased price stability',
    accentedText: 'Token projects',
    content: 'Smooth and secure launch experience with cross-chain and cross-AMM support, and backing from blue-chip assets.',
  },
  {
    title: 'Idle tokens activation',
    accentedText: 'DAO treasuries',
    content: 'With ICHI, your DAO’s idle tokens won’t just sit there. Turn them into active liquidity, generating returns and boosting treasury’s value.',
  },
  {
    title: 'Smarter capital deployment',
    accentedText: 'Asset managers',
    content: 'Take the guesswork out of capital deployment with automated tools that strategically allocate assets and optimize yields.',
  },
]

interface FAQItem {
  question: string;
  answer: ReactNode;
}


export const faqPoints: FAQItem[] = [
  {
    question: 'How Can I Start Using ICHI?',
    answer: (
      <div>
        <p>Starting with ICHI is straightforward: </p>
        <ol className='faqList'>
          <li>1. Access the Platform: Visit the ICHI App or one of the partner platforms.</li>
          <li>2. Connect Your Wallet: Securely connect your digital wallet.</li>
          <li>3. Select Your Token: Choose the token you wish to deposit.</li>
          <li>4. Follow the Prompts: Complete the deposit process by following the onscreen instructions.</li>
        </ol>
        < p > Tutorials and guides are available on the platforms to assist users through the process.</p>
      </div>
    ),
  },
  {
    question: 'Which Tokens are Supported by ICHI?',
    answer: (
      <div>
        <p>ICHI supports a wide range of popular tokens. For the most uptodate list of supported tokens, please check the platform or any of the partner platforms listed above.</p>
      </div>
    ),
  },
  {
    question: 'Is ICHI Safe?',
    answer: (
      <div>
        <p>ICHI takes security seriously and has undergone multiple audits. However, it's important to note that risks inherent to decentralized finance (DeFi) still exist. Users should stay informed and vigilant about potential risks, conducting thorough research before engaging with the platform. Always invest what you can afford to lose.</p>
      </div>
    ),
  },
  {
    question: 'How Does ICHI Manage Market Volatility?',
    answer: (
      <div>
        <p>ICHI actively responds to market volatility by dynamically adjusting liquidity positions. During periods of extreme volatility, the vault may lock to prevent further deposits until the situation stabilizes, ensuring the protection of users' assets.</p>
      </div>
    ),
  },
  // {
  //   question: 'How is ICHI Different from Traditional Staking?',
  //   answer: (
  //     <div>
  //       <p>ICHI distinguishes itself from traditional staking methods by enabling users to earn returns through both token appreciation and trading fees, all via a singlesided deposit. Traditional staking typically relies on protocolmanaged emissions, which can limit earnings potential. In contrast, ICHI simplifies the staking process by allowing users to deposit a single token and benefit from automated liquidity management, maximizing returns while minimizing effort.</p>
  //     </div>
  //   ),
  // },
  // {
  //   question: 'What is the LongTerm Vision for ICHI?',
  //   answer: (
  //     <div>
  //       <p>ICHI aims to revolutionize decentralized finance (DeFi) by creating an accessible, efficient platform for liquidity provision. The longterm vision is to democratize DeFi opportunities, empowering individuals and communities to seamlessly participate in the decentralized economy. Through continuous innovation and usercentric design, ICHI seeks to become a cornerstone of the DeFi ecosystem, providing robust solutions for liquidity management and yield optimization.</p>
  //     </div>
  //   ),
  // },
  // {
  //   question: 'Who is Behind ICHI?',
  //   answer: (
  //     <div>
  //       <p>ICHI is developed and managed by a diverse group of contributors and entities working collaboratively. Its governance and development are conducted through decentralized smart contracts, ensuring transparency and communitydriven decisionmaking. The project benefits from the combined efforts of the ICHI team and various collaborators, fostering a dynamic and resilient ecosystem.</p>
  //     </div>
  //   ),
  // },
]